import { Account, Facility, Tag } from '@prisma/client'
import axios from 'axios'
import { create } from 'zustand'
import { Facilities } from '@/app/api/facilities/route'
import { format, getQuarter, subQuarters } from 'date-fns'
import { uniqBy } from 'lodash'

export type Quarter = {
  label: string
  value: string
}

export type GlobalSettingsStore = {
  ready: boolean
  userId: string | null
  selectedFacilities: Facility[]
  facilities: Facility[]
  facilityIds: number[]
  currentAccount: Account | null
  selectedQuarter: Quarter
  availableQuarters: Quarter[]
  selectedRegion: Tag[] | []
  regions: Tag[]
  setReady: (ready: boolean) => void
  setUserId: (userId: string) => void
  setSelectedFacilities: (facilities: Facility[]) => void
  setCurrentAccount: (account: Account) => void
  resetCurrentAccount: (account: Account) => void
  setSelectedQuarter: (quarter: Quarter) => void
  setSelectedRegion: (region: Tag[]) => void
  setFacilityIds: (facilityIds: number[]) => void
}

export const LOCAL_STORAGE_KEY = 'globalSettings-3'

const getUrlSearch = () => {
  return window.location.search.slice(1)
}

const generateQuarters = (): Quarter[] => {
  const currentDate = new Date()
  const quarters = []
  for (let i = 0; i < 3; i++) {
    quarters.push({
      label: `Q${getQuarter(subQuarters(currentDate, i))} ${format(subQuarters(currentDate, i), 'yyyy')}`,
      value: format(subQuarters(currentDate, i), 'yyyy-MM-dd'),
    })
  }

  return quarters
}

const initialDataState = {
  facilities: [],
  facilityIds: [],
  regions: [],
  selectedFacilities: [],
  selectedRegion: [],
}

export const useGlobalSettingsStore = create<GlobalSettingsStore>()((set, get) => {
  const initialQuarters = generateQuarters()

  return {
    ...initialDataState,
    ready: false,
    userId: null,
    currentAccount: null,
    selectedQuarter: initialQuarters[0],
    availableQuarters: initialQuarters,
    setReady: (ready: boolean) => set({ ready }),
    setUserId: (userId: string) => set({ userId }),
    setSelectedFacilities: (facilities: Facility[]) => {
      set({ selectedFacilities: facilities })
      set({ selectedRegion: [] })
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          get().userId || LOCAL_STORAGE_KEY,
          JSON.stringify({ ...get(), selectedFacilities: facilities }),
        )
      }
      const searchParams = new URLSearchParams(getUrlSearch())

      if (facilities?.length) {
        searchParams.set('fac', facilities.map(f => f.id).join(','))
      } else {
        searchParams.delete('fac')
      }

      window.history.replaceState(null, '', `?${searchParams.toString()}`)
    },
    setCurrentAccount: async (account: Account) => {
      try {
        set({ currentAccount: account })
        const response = await axios.get<Facilities>(`/api/facilities?accountId=${account.id}`)
        if (response.data.facilities.length === 0) {
          throw new Error('No facilities found boi')
        }

        const tags = response.data.facilities.map(facility => facility.tags).flat()

        const uniqueTags = uniqBy(tags, 'id')

        set({ facilities: response.data.facilities })
        set({
          regions: uniqueTags,
        })
        set({ facilityIds: response.data.facilities.map(facility => facility.id) })

        if (typeof window !== 'undefined') {
          localStorage.setItem(get().userId || LOCAL_STORAGE_KEY, JSON.stringify({ ...get(), currentAccount: account }))
        }
      } catch (error) {
        console.error(error)
      }
    },
    resetCurrentAccount: async (account: Account) => {
      set(initialDataState)
      get().setCurrentAccount(account)
    },
    setSelectedQuarter: (quarter: Quarter) => {
      set({ selectedQuarter: quarter })
    },
    setSelectedRegion: (regions: Tag[]) => {
      set({ selectedRegion: regions })
    },
    setFacilityIds: (facilityIds: number[]) => {
      set({ facilityIds })
    },
  }
})
